import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './EmailEditor.module.css';
import { useAuth } from '../contexts/AuthContext';
import { saveLetterDraft } from '../services/neojiva_api';
import ErrorToast from './ErrorToast';
import SuccessToast from './SuccessToast';

// Define the font whitelist with proper labels
const FontAttributor = ReactQuill.Quill.import('attributors/class/font');
FontAttributor.whitelist = [
  'arial',
  'helvetica',
  'times-new-roman',
  'courier',
  'verdana',
  'georgia',
  'palatino',
  'garamond',
  'bookman',
  'comic-sans-ms',
  'trebuchet-ms',
  'arial-black',
  'impact'
];

const fonts = [
  { label: 'Arial', value: 'arial' },
  { label: 'Helvetica', value: 'helvetica' },
  { label: 'Times New Roman', value: 'times-new-roman' },
  { label: 'Courier', value: 'courier' },
  { label: 'Verdana', value: 'verdana' },
  { label: 'Georgia', value: 'georgia' },
  { label: 'Palatino', value: 'palatino' },
  { label: 'Garamond', value: 'garamond' },
  { label: 'Bookman', value: 'bookman' },
  { label: 'Comic Sans MS', value: 'comic-sans-ms' },
  { label: 'Trebuchet MS', value: 'trebuchet-ms' },
  { label: 'Arial Black', value: 'arial-black' },
  { label: 'Impact', value: 'impact' }
];

ReactQuill.Quill.register(FontAttributor, true);

const EmailEditor = ({ initialDraft, onClose }) => {
  const [receiver, setReceiver] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [sizeError, setSizeError] = useState('');
  const [letterUid, setLetterUid] = useState(null);
  const [letterState, setLetterState] = useState('Draft');
  const { currentUser } = useAuth();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (initialDraft) {
      setReceiver(initialDraft.email_to_send || '');
      setSubject(initialDraft.subject || '');
      setBody(initialDraft.txt_content || '');
      setLetterUid(initialDraft.letter_uid || null);
      setLetterState(initialDraft.state || 'Draft');
    }
  }, [initialDraft]);

  const handleSave = async (state = 'Draft') => {
    const emailSize = new Blob([receiver, subject, body]).size;
    const maxSize = 10 * 1024 * 1024; // 10MB in bytes

    if (emailSize > maxSize) {
      setErrorMessage('Email size exceeds 10MB limit.');
      return;
    }

    const letterData = {
      creator_user_id: currentUser.uid,
      type: 'TXT',
      email_to_send: receiver,
      txt_content: body,
      subject: subject,
      state: state
    };

    try {
      const savedLetter = await saveLetterDraft(letterData, letterUid);
      setLetterUid(savedLetter.letter_uid);
      setSuccessMessage(`Letter ${state === 'Draft' ? 'saved' : 'scheduled'} successfully!`);
      setLetterState(state);
    } catch (error) {
      console.error('Error saving letter:', error);
      setErrorMessage(`Failed to ${state === 'Draft' ? 'save' : 'schedule'} letter. Please try again.`);
    }
  };

  const handleSchedule = () => {
    handleSave('Scheduled');
  };

  const handleCloseSuccess = () => {
    setSuccessMessage('');
  };

  const handleCloseError = () => {
    setErrorMessage('');
  };

  const modules = {
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': fonts.map(font => font.value) }], // Use the font values here
        [{ 'align': [] }],
        ['link', 'image'],
        ['clean']
      ],
    },
    clipboard: {
      matchVisual: false,
    }
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'color', 'background',
    'font',
    'align',
    'link', 'image'
  ];

  return (
    <div className={styles.editorContainer}>
      <input
        type="email"
        value={receiver}
        onChange={(e) => setReceiver(e.target.value)}
        placeholder="Receiver Email"
        className={styles.receiverInput}
      />
      <input
        type="text"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        placeholder="Subject"
        className={styles.subjectInput}
      />
      <ReactQuill
        value={body}
        onChange={setBody}
        modules={modules}
        formats={formats}
        className={styles.bodyInput}
        placeholder="Write your email here..."
      />
      {sizeError && <div className={styles.errorMessage}>{sizeError}</div>}
      <div className={styles.buttonContainer}>
        {letterState === 'Draft' ? (
          <>
            <button onClick={handleSchedule} className={styles.scheduleButton}>Schedule</button>
            <button onClick={() => handleSave('Draft')} className={styles.saveButton}>Save Draft</button>
          </>
        ) : (
          <button onClick={handleSchedule} className={styles.updateButton}>Update</button>
        )}
        <button onClick={onClose} className={styles.closeButton}>Close</button>
      </div>
      {successMessage && <SuccessToast message={successMessage} onClose={handleCloseSuccess} />}
      {errorMessage && <ErrorToast message={errorMessage} onClose={handleCloseError} />}
    </div>
  );
};

export default EmailEditor;