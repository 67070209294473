import axios from 'axios';
import { getAuth } from 'firebase/auth';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000/api';

export const registerUserInBackend = async (user) => {
  try {
    // Get the current Firebase user
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error('No authenticated user found');
    }

    // Get the Firebase ID token
    const idToken = await currentUser.getIdToken(true);

    // Set up the headers with the Firebase token
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}`
    };

    const response = await axios.post(`${API_BASE_URL}/users/`, {
      firebase_uid: user.uid,
      email: user.email,
      full_name: user.displayName || null,
      is_active: true,
      preferences: {},
      metadata: {},
      default_countdown_days: 7
    }, { headers });

    return response.data;
  } catch (error) {
    console.error('Error registering user in backend:', error);
    throw error;
  }
};

export const fetchUserInfo = async () => {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error('No authenticated user found');
    }

    const idToken = await currentUser.getIdToken(true);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}`
    };

    const response = await axios.get(`${API_BASE_URL}/users/${currentUser.uid}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching user info:', error);
    throw error;
  }
};

export const resetCountdown = async () => {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error('No authenticated user found');
    }

    const idToken = await currentUser.getIdToken(true);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}`
    };

    const response = await axios.post(`${API_BASE_URL}/users/reset-countdown`, {}, { headers });
    return response.data.countdown_start_date;
  } catch (error) {
    console.error('Error resetting countdown:', error);
    throw error;
  }
};

export const updateDefaultCountdownDays = async (days) => {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error('No authenticated user found');
    }

    const idToken = await currentUser.getIdToken(true);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}`
    };

    const response = await axios.post(`${API_BASE_URL}/users/reset-countdown`, { new_countdown_days: days }, { headers });
    return response.data;
  } catch (error) {
    console.error('Error updating default countdown days:', error);
    throw error;
  }
};

export const updateUserProfile = async (userData) => {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error('No authenticated user found');
    }

    const idToken = await currentUser.getIdToken(true);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}`
    };

    const response = await axios.put(`${API_BASE_URL}/users/${currentUser.uid}`, userData, { headers });
    return response.data;
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};

export const fetchLettersList = async () => {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error('No authenticated user found');
    }

    const idToken = await currentUser.getIdToken(true);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}`
    };

    const response = await axios.post(`${API_BASE_URL}/list-letters`, {
      creator_user_id: currentUser.uid
    }, { headers });

    return response.data;
  } catch (error) {
    console.error('Error fetching letters list:', error);
    throw error;
  }
};

export const saveLetterDraft = async (letterData, letterUid = null) => {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error('No authenticated user found');
    }

    const idToken = await currentUser.getIdToken(true);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}`
    };

    let response;
    if (letterUid) {
      // Update existing letter
      response = await axios.put(`${API_BASE_URL}/letters/${letterUid}`, letterData, { headers });
    } else {
      // Create new letter
      response = await axios.post(`${API_BASE_URL}/letters/`, letterData, { headers });
    }

    return response.data;
  } catch (error) {
    console.error('Error saving letter draft:', error);
    throw error;
  }
};

export const moveToDraft = async (letterUid) => {
};

export const deleteLetter = async (letterUid) => {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error('No authenticated user found');
    }

    const idToken = await currentUser.getIdToken(true);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}`
    };

    const response = await axios.post(`${API_BASE_URL}/letters/${letterUid}/delete`, {
      creator_user_id: currentUser.uid
    }, { headers });

    return response.data;
  } catch (error) {
    console.error('Error deleting letter:', error);
    throw error;
  }
};

export const fetchLetterDetails = async (letterUid) => {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error('No authenticated user found');
    }

    const idToken = await currentUser.getIdToken(true);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}`
    };

    const response = await axios.post(`${API_BASE_URL}/letters/${letterUid}`, {
      creator_user_id: currentUser.uid
    }, { headers });

    return response.data;
  } catch (error) {
    console.error('Error fetching letter details:', error);
    throw error;
  }
};

export const getVideoUploadUrls = async (videoData) => {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error('No authenticated user found');
    }

    const idToken = await currentUser.getIdToken(true);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}`
    };

    const response = await axios.post(`${API_BASE_URL}/video/get-upload-url`, {
      ...videoData,
      origin: window.location.origin // Send the frontend origin
    }, { headers });
    return response.data;
  } catch (error) {
    console.error('Error getting video upload URL:', error);
    throw error;
  }
};

export const completeVideoUpload = async (letterUid, videoMetadata) => {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error('No authenticated user found');
    }

    const idToken = await currentUser.getIdToken(true);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}`
    };

    const response = await axios.post(`${API_BASE_URL}/video/${letterUid}/complete`, videoMetadata, { headers });
    return response.data;
  } catch (error) {
    console.error('Error completing video upload:', error);
    throw error;
  }
};

export const getVideoPlaybackUrl = async (letterUid) => {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error('No authenticated user found');
    }

    const idToken = await currentUser.getIdToken(true);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}`
    };

    const response = await axios.get(`${API_BASE_URL}/video/${letterUid}/playback-url`, {
      headers,
      params: { creator_user_id: currentUser.uid }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching video playback URL:', error);
    throw error;
  }
};

export const updateLetterState = async (letterUid, stateData) => {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error('No authenticated user found');
    }

    const idToken = await currentUser.getIdToken(true);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}`
    };

    const response = await axios.put(`${API_BASE_URL}/letters/${letterUid}/state`, stateData, { headers });
    return response.data;
  } catch (error) {
    console.error('Error updating letter state:', error);
    throw error;
  }
};
