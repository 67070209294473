import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { PenSquare, FileText, Video, Upload } from 'lucide-react';
import styles from './Welcome.module.css';
import EmailEditor from './EmailEditor';
import VideoRecorder from './VideoRecorder';
import VideoUploader from './VideoUploader';
import { fetchLettersList } from '../services/neojiva_api';
import LetterTile from './LetterTile';
import ErrorToast from './ErrorToast';
import VideoPlayer from './VideoPlayer';

function Welcome() {
  const { currentUser, userInfo } = useAuth();
  const [showEditor, setShowEditor] = useState(false);
  const [currentDraft, setCurrentDraft] = useState(null);
  const [letters, setLetters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editorPosition, setEditorPosition] = useState({ top: 0, left: 0 });
  const [showComposeMenu, setShowComposeMenu] = useState(false);
  const [composeMenuPosition, setComposeMenuPosition] = useState({ bottom: 0, right: 0 });
  const composeButtonRef = useRef(null);
  const composeMenuRef = useRef(null);
  const [showVideoRecorder, setShowVideoRecorder] = useState(false);
  const [showVideoUploader, setShowVideoUploader] = useState(false);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [currentVideoLetter, setCurrentVideoLetter] = useState(null);

  useEffect(() => {
    fetchLetters();
  }, []);

  const fetchLetters = async () => {
    try {
      const fetchedLetters = await fetchLettersList();
      setLetters(fetchedLetters);
    } catch (error) {
      console.error('Error fetching letters:', error);
      setError('Failed to fetch letters. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (composeMenuRef.current && !composeMenuRef.current.contains(event.target) &&
          composeButtonRef.current && !composeButtonRef.current.contains(event.target)) {
        setShowComposeMenu(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCompose = (e) => {
    e.preventDefault();
    if (composeButtonRef.current) {
      const buttonRect = composeButtonRef.current.getBoundingClientRect();
      setComposeMenuPosition({
        bottom: window.innerHeight - buttonRect.top + 10,
        right: window.innerWidth - buttonRect.right,
      });
      setShowComposeMenu(!showComposeMenu); // Toggle the menu
    }
  };

  const handleComposeOption = (option) => {
    console.log("Compose option selected:", option); // Debug log
    setShowComposeMenu(false);
    switch (option) {
      case 'text':
        setCurrentDraft(null);
        setShowEditor(true);
        setEditorPosition({ top: 0, left: 0 });
        break;
      case 'video':
        setShowVideoRecorder(true);
        break;
      case 'upload':
        setShowVideoUploader(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    console.log("showComposeMenu:", showComposeMenu); // Debug log
  }, [showComposeMenu]);

  const handleEditorClose = () => {
    setShowEditor(false);
    fetchLetters(); // Refresh the letters list when closing the editor
  };

  const handleLetterClick = (letterDetails) => {
    if (letterDetails.type === 'VID') {
      setCurrentVideoLetter(letterDetails);
      setShowVideoPlayer(true);
    } else {
      setCurrentDraft(letterDetails);
      setShowEditor(true);
    }
    setEditorPosition({ top: 0, left: 0 });
  };

  const handleCloseError = () => {
    setError(null);
  };

  const handleVideoRecorderClose = () => {
    setShowVideoRecorder(false);
    fetchLetters(); // Refresh the letters list when closing the video recorder
  };

  const handleVideoUploaderClose = () => {
    setShowVideoUploader(false);
    fetchLetters(); // Refresh the letters list when closing the video uploader
  };

  const handleVideoPlayerClose = () => {
    setShowVideoPlayer(false);
    setCurrentVideoLetter(null);
  };

  const handleLetterUpdate = () => {
    fetchLetters();
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          Welcome, {userInfo?.full_name || currentUser?.displayName || currentUser?.email}!
        </h1>
      </div>
      <button className={styles.composeButton} onClick={handleCompose} ref={composeButtonRef}>
        <PenSquare size={20} />
        <span>Compose</span>
      </button>

      {showComposeMenu && (
        <div 
          ref={composeMenuRef}
          className={styles.composeMenu} 
          style={{ 
            bottom: `${composeMenuPosition.bottom}px`, 
            right: `${composeMenuPosition.right}px`,
          }}
        >
          <button onClick={() => handleComposeOption('text')}>
            <FileText size={18} />
            <span>Create text letter</span>
          </button>
          <button onClick={() => handleComposeOption('video')}>
            <Video size={18} />
            <span>Create video letter</span>
          </button>
          <button onClick={() => handleComposeOption('upload')}>
            <Upload size={18} />
            <span>Upload a Video</span>
          </button>
        </div>
      )}

      {loading && <p>Loading letters...</p>}

      <div className={styles.lettersContainer}>
        <div className={styles.letterGrid}>
          {letters.map((letter) => (
            <LetterTile 
              key={letter.letter_uid} 
              letter={letter} 
              onLetterClick={handleLetterClick} 
              onLetterUpdate={handleLetterUpdate}
            />
          ))}
        </div>
      </div>

      {showEditor && (
        <div className={styles.editorOverlay} style={{ top: editorPosition.top, left: editorPosition.left }}>
          <EmailEditor
            initialDraft={currentDraft}
            onClose={handleEditorClose}
          />
        </div>
      )}

      {showVideoRecorder && (
        <div className={styles.editorOverlay}>
          <VideoRecorder
            onSave={() => {
              handleVideoRecorderClose();
              // Add any additional logic for saving the video
            }}
            onClose={handleVideoRecorderClose}
          />
        </div>
      )}

      {showVideoUploader && (
        <div className={styles.editorOverlay}>
          <VideoUploader
            onSave={() => {
              handleVideoUploaderClose();
              // Add any additional logic for saving the uploaded video
            }}
            onClose={handleVideoUploaderClose}
          />
        </div>
      )}

      {showVideoPlayer && (
        <div className={styles.editorOverlay}>
          <VideoPlayer
            letterDetails={currentVideoLetter}
            onClose={handleVideoPlayerClose}
          />
        </div>
      )}

      {error && <ErrorToast message={error} onClose={handleCloseError} />}
    </div>
  );
}

export default Welcome;