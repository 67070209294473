import React, { useEffect, useState } from 'react';
import styles from './SuccessToast.module.css';

const SuccessToast = ({ message, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (!isVisible) {
      const fadeOutTimer = setTimeout(() => {
        onClose();
      }, 500);

      return () => clearTimeout(fadeOutTimer);
    }
  }, [isVisible, onClose]);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <div className={`${styles.successToast} ${!isVisible ? styles.fadeOut : ''}`}>
      <p>{message}</p>
      <button className={styles.closeButton} onClick={handleClose}>
        &times;
      </button>
    </div>
  );
};

export default SuccessToast;