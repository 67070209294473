// LetterTile.js
import React, { useState, useEffect, useRef } from 'react';
import { Mail, Calendar, Video, MoreHorizontal } from 'lucide-react';
import { motion } from 'framer-motion';
import { formatDistanceToNow } from 'date-fns';
import styles from './LetterTile.module.css';
import { fetchLetterDetails, updateLetterState, deleteLetter } from '../services/neojiva_api';
import ErrorToast from './ErrorToast';
import ConfirmationModal from './ConfirmationModal';
import { useAuth } from '../contexts/AuthContext';

const LetterTile = ({ letter, onLetterClick, onLetterUpdate }) => {
  const [error, setError] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const menuRef = useRef(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const formatDate = (dateString) => {
    return formatDistanceToNow(new Date(dateString), { addSuffix: true });
  };

  const handleClick = async () => {
    try {
      const letterDetails = await fetchLetterDetails(letter.letter_uid);
      onLetterClick({...letterDetails, type: letter.type});
    } catch (error) {
      console.error('Error fetching letter details:', error);
      setError('Failed to fetch letter details. Please try again.');
    }
  };

  const handleCloseError = () => {
    setError(null);
  };

  const isVideoLetter = letter.type === 'VID';

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  const handleStateChange = async (e, newState) => {
    e.stopPropagation();
    try {
      await updateLetterState(letter.letter_uid, {
        creator_user_id: currentUser.uid,
        state: newState
      });
      onLetterUpdate();
    } catch (error) {
      setError(`Failed to move letter to ${newState.toLowerCase()} state. Please try again.`);
    }
    setShowMenu(false);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    setShowConfirmModal(true);
    setShowMenu(false);
  };

  const confirmDelete = async () => {
    try {
      await deleteLetter(letter.letter_uid);
      onLetterUpdate();
    } catch (error) {
      setError('Failed to delete letter. Please try again.');
    }
    setShowConfirmModal(false);
  };

  return (
    <>
      <motion.div 
        className={`${styles.letterCard} ${styles.videoLetterCard}`}
        whileHover={{ scale: 1.02 }}
        transition={{ type: "tween", duration: 0.2 }}
        onClick={handleClick}
      >
        <div className={styles.letterContent}>
          {/* Icon Container */}
          <div className={styles.iconContainer}>
            <div className={styles.mailIconWrapper}>
              {isVideoLetter ? (
                <Video className={styles.videoIcon} />
              ) : (
                <Mail className={styles.mailIcon} />
              )}
            </div>
          </div>

          {/* Letter Details */}
          <div className={styles.letterDetails}>
            <h3 className={styles.letterTitle}>
              {letter.subject || 'No subject'}
            </h3>
            <p className={styles.letterRecipient}>
              To: {letter.email_to_send || 'No recipient'}
            </p>
          </div>

          {/* Footer */}
          <div className={styles.letterFooter}>
            <div className={styles.dateContainer}>
              <Calendar className={styles.calendarIcon} />
              <span>
                {formatDate(letter.updated_at || letter.created_at)}
              </span>
            </div>
            <span className={`${styles.statusBadge} ${styles[`state${letter.state}`]}`}>
              {letter.state}
            </span>
          </div>

          {/* Add kebab menu */}
          <div className={styles.kebabMenu} ref={menuRef}>
            <button onClick={handleMenuClick} className={styles.kebabButton}>
              <MoreHorizontal size={18} />
            </button>
            {showMenu && (
              <div className={styles.menuOptions}>
                {letter.state === 'Draft' && (
                  <button onClick={(e) => handleStateChange(e, 'Scheduled')}>Schedule Letter</button>
                )}
                {letter.state === 'Scheduled' && (
                  <button onClick={(e) => handleStateChange(e, 'Draft')}>Move to Draft</button>
                )}
                <button onClick={handleDelete}>Delete Letter</button>
              </div>
            )}
          </div>
        </div>
      </motion.div>
      {error && <ErrorToast message={error} onClose={handleCloseError} />}
      <ConfirmationModal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={confirmDelete}
        message="Are you sure you want to delete this letter? This action cannot be undone and all content will be permanently lost."
      />
    </>
  );
};

export default LetterTile;