import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Clock, RotateCcw, Settings, X } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { resetCountdown, updateDefaultCountdownDays } from '../services/neojiva_api';
import styles from './CompactCountdownTimer.module.css';
import ErrorToast from './ErrorToast';

const CompactCountdownTimer = ({ countdownStartDate, defaultCountdownDays }) => {
  const [remainingTime, setRemainingTime] = useState(null);
  const [isResetting, setIsResetting] = useState(false);
  const [error, setError] = useState(null);
  const [showSettings, setShowSettings] = useState(false);
  const [newCountdownDays, setNewCountdownDays] = useState(defaultCountdownDays);
  const { setUserInfo } = useAuth();
  const errorTimeoutRef = useRef(null);

  useEffect(() => {
    if (countdownStartDate && defaultCountdownDays) {
      const startDate = new Date(countdownStartDate);
      const endDate = new Date(startDate.getTime() + defaultCountdownDays * 24 * 60 * 60 * 1000);
      
      const updateRemainingTime = () => {
        const now = new Date();
        const timeDiff = endDate - now;
        if (timeDiff > 0) {
          setRemainingTime(timeDiff);
        } else {
          setRemainingTime(0);
        }
      };

      updateRemainingTime();
      const interval = setInterval(updateRemainingTime, 1000);

      return () => clearInterval(interval);
    }
  }, [countdownStartDate, defaultCountdownDays]);

  const formatTime = (ms) => {
    if (!ms) return '0d 0h 0m 0s';
    
    const days = Math.floor(ms / (24 * 60 * 60 * 1000));
    const hours = Math.floor((ms % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    const minutes = Math.floor((ms % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((ms % (60 * 1000)) / 1000);

    // Show only seconds if days=0, hours=0, and minutes<10
    if (days === 0 && hours === 0 && minutes < 10) {
      return `${hours}h ${minutes}m ${seconds}s`;
    }

    return `${days}d ${hours}h ${minutes}m`;
  };

  const handleRestartTimer = async () => {
    setIsResetting(true);
    setError(null);
    try {
      const countdown_start_date = await resetCountdown();
      if (!countdown_start_date) {
        throw new Error('Invalid response format');
      }
      
      setUserInfo(prevUserInfo => ({
        ...prevUserInfo,
        countdown_start_date
      }));
    } catch (error) {
      console.error('Failed to reset countdown:', error);
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
      setError('Error resetting counter. Please try again later.');
      errorTimeoutRef.current = setTimeout(() => {
        setError(null);
      }, 4500);
    } finally {
      setIsResetting(false);
    }
  };

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value)) {
      setNewCountdownDays(Math.max(2, Math.min(365, value)));
    }
  };

  const handleUpdateCountdownDays = async () => {
    if (newCountdownDays < 2 || newCountdownDays > 365) {
      setError('Please enter a value between 2 and 365 days.');
      return;
    }

    setIsResetting(true);
    setError(null);
    try {
      const updatedUser = await updateDefaultCountdownDays(newCountdownDays);
      setUserInfo(prevUserInfo => ({
        ...prevUserInfo,
        default_countdown_days: updatedUser.default_countdown_days,
        countdown_start_date: updatedUser.countdown_start_date
      }));
      setShowSettings(false);
    } catch (error) {
      console.error('Failed to update countdown days:', error);
      setError('Error updating countdown days. Please try again later.');
    } finally {
      setIsResetting(false);
    }
  };

  const handleCloseError = useCallback(() => {
    if (errorTimeoutRef.current) {
      clearTimeout(errorTimeoutRef.current);
    }
    setError(null);
    console.log('Error cleared by handleCloseError');
  }, []);

  useEffect(() => {
    return () => {
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <div className={styles.timerContainer}>
        <button
          className={styles.timerButton}
          type="button"
          onClick={() => setShowSettings(true)}
        >
          <Clock className={styles.timerIcon} />
          <span>
            {remainingTime === null ? "Loading..." : formatTime(remainingTime)}
          </span>
        </button>

        {remainingTime !== null && (
          <div className={styles.timerControls}>
            <button
              onClick={handleRestartTimer}
              className={`${styles.controlButton} ${styles.restartButton}`}
              type="button"
              disabled={isResetting}
            >
              <RotateCcw className={styles.buttonIcon} />
              <span>{isResetting ? 'Restarting...' : 'Restart'}</span>
            </button>
          </div>
        )}
      </div>

      {showSettings && (
        <div className={styles.modalOverlay}>
          <div className={styles.modal}>
            <button
              className={styles.closeButton}
              onClick={() => setShowSettings(false)}
            >
              <X size={24} />
            </button>
            <h2 className={styles.modalTitle}>Reset Countdown Timer</h2>
            <div className={styles.inputGroup}>
              <label htmlFor="countdownDays">Countdown Days (2-365):</label>
              <input
                id="countdownDays"
                type="number"
                value={newCountdownDays}
                onChange={handleInputChange}
                onBlur={() => {
                  if (newCountdownDays < 2) setNewCountdownDays(2);
                  if (newCountdownDays > 365) setNewCountdownDays(365);
                }}
                min="2"
                max="365"
              />
            </div>
            <button
              onClick={handleUpdateCountdownDays}
              className={`${styles.controlButton} ${styles.updateButton}`}
              type="button"
              disabled={isResetting || newCountdownDays < 2 || newCountdownDays > 365}
            >
              <Settings className={styles.buttonIcon} />
              <span>{isResetting ? 'Updating...' : 'Update and Restart'}</span>
            </button>
          </div>
        </div>
      )}

      {error && <ErrorToast message={error} onClose={handleCloseError} />}
    </>
  );
};

export default CompactCountdownTimer;