import React, { useState, useRef } from 'react';
import axios from 'axios';
import styles from './VideoUploader.module.css';
import { getVideoUploadUrls, completeVideoUpload } from '../services/neojiva_api';
import { useAuth } from '../contexts/AuthContext';

const VideoUploader = ({ onSave, onClose }) => {
  const [receiver, setReceiver] = useState('');
  const [subject, setSubject] = useState('');
  const [video, setVideo] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState('');
  const { currentUser } = useAuth();
  const videoRef = useRef(null);

  const MAX_FILE_SIZE = 500 * 1024 * 1024; // 500MB in bytes

  const generateThumbnail = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.playsInline = true;
      video.muted = true;

      video.onloadedmetadata = () => {
        video.currentTime = 1; // Set to 1 second or any desired time
      };

      video.onseeked = () => {
        const canvas = document.createElement('canvas');
        canvas.width = 320;
        canvas.height = 180;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          console.log('Thumbnail blob size:', blob.size);
          resolve(blob);
        }, 'image/jpeg', 0.7);
      };

      video.onerror = (e) => {
        console.error('Error in video element:', e);
        reject(e);
      };

      video.src = URL.createObjectURL(file);
      video.load();
    });
  };

  const handleSubmit = async (e, state) => {
    e.preventDefault();
    if (!video) return;

    if (video.size > MAX_FILE_SIZE) {
      setError('File size exceeds 500MB limit. Please choose a smaller file.');
      return;
    }

    try {
      // Get signed URLs for both video and thumbnail
      const urlsResponse = await getVideoUploadUrls({
        content_type: video.type,
        file_size: video.size,
        subject,
        email_to_send: receiver,
        creator_user_id: currentUser.uid
      });

      const { video_upload_url, thumbnail_upload_url, letter_uid } = urlsResponse;

      // Upload video
      await uploadInChunks(video, video_upload_url);

      // Upload thumbnail
      console.log('Thumbnail size before upload:', thumbnail.size);
      await axios.put(thumbnail_upload_url, thumbnail, {
        headers: {
          'Content-Type': 'image/jpeg',
        },
      });
      console.log('Thumbnail uploaded successfully');

      // Mark upload as complete
      await completeVideoUpload(letter_uid, {
        letter_uid,
        duration_seconds: videoRef.current.duration,
        creator_user_id: currentUser.uid,
        state: state // Add this line to set the state
      });

      onSave({ receiver, subject, letterUid: letter_uid, state: state });
    } catch (error) {
      console.error('Error uploading video:', error);
      setError('An error occurred while uploading the video. Please try again.');
    }
  };

  const uploadInChunks = async (file, uploadUrl) => {
    try {
      await axios.put(uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
          'Content-Length': file.size,
        },
        withCredentials: false,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      }
      throw error;
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    console.log('Selected file:', file);
    if (file && file.size > MAX_FILE_SIZE) {
      setError('File size exceeds 500MB limit. Please choose a smaller file.');
      setVideo(null);
      setThumbnail(null);
    } else {
      setError('');
      setVideo(file);
      try {
        const thumbnailBlob = await generateThumbnail(file);
        console.log('Generated thumbnail size:', thumbnailBlob.size);
        setThumbnail(thumbnailBlob);
      } catch (error) {
        console.error('Error generating thumbnail:', error);
        setError('Failed to generate thumbnail. Please try again.');
      }
    }
  };

  return (
    <div className={styles.container}>
      <h2>Upload a Video</h2>
      <form onSubmit={(e) => e.preventDefault()}>
        <input
          type="email"
          value={receiver}
          onChange={(e) => setReceiver(e.target.value)}
          placeholder="Receiver Email"
          required
        />
        <input
          type="text"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          placeholder="Subject (optional)"
        />
        <input
          type="file"
          accept="video/*"
          onChange={handleFileChange}
          required
        />
        {thumbnail && (
          <div className={styles.thumbnailPreview}>
            <img src={URL.createObjectURL(thumbnail)} alt="Video thumbnail" />
          </div>
        )}
        {error && <p className={styles.error}>{error}</p>}
        {uploadProgress > 0 && <progress value={uploadProgress} max="100" />}
        <div className={styles.buttonContainer}>
          <button 
            type="button" 
            onClick={(e) => handleSubmit(e, 'Scheduled')} 
            disabled={!video || error}
            className={styles.scheduleButton}
          >
            Schedule Now
          </button>
          <button 
            type="button" 
            onClick={(e) => handleSubmit(e, 'Draft')} 
            disabled={!video || error}
            className={styles.saveButton}
          >
            Save as Draft
          </button>
          <button 
            type="button" 
            onClick={onClose}
            className={styles.closeButton}
          >
            Close
          </button>
        </div>
      </form>
      <video ref={videoRef} style={{ display: 'none' }} />
    </div>
  );
};

export default VideoUploader;