import React, { useState, useEffect } from 'react';
import { Download } from "lucide-react";
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import styles from './ManageAccount.module.css';
import { fetchUserInfo, updateUserProfile } from '../services/neojiva_api';
import ErrorToast from './ErrorToast';

const ManageAccount = () => {
  const [activeTab, setActiveTab] = useState('profile');
  const [user, setUser] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const { currentUser, setUserInfo } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [newFullName, setNewFullName] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const userData = await fetchUserInfo();
          setUser(userData);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    const fetchInvoices = async () => {
      if (currentUser) {
        try {
          const idToken = await currentUser.getIdToken(true);
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/${currentUser.uid}/invoices`, {
            headers: {
              'Authorization': `Bearer ${idToken}`
            }
          });
          setInvoices(response.data);
        } catch (error) {
          console.error('Error fetching invoices:', error);
        }
      }
    };

    fetchUserData();
    fetchInvoices();
  }, [currentUser]);

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  const handleEditProfile = () => {
    setNewFullName(user.full_name || '');
    setIsEditing(true);
  };

  const handleSaveProfile = async () => {
    const { isValid, error } = validateName(newFullName);
    
    if (!isValid) {
      setError(error);
      return;
    }

    try {
      const updatedUser = await updateUserProfile({ full_name: newFullName });
      setUser(updatedUser);
      setIsEditing(false);
      
      // Update the userInfo in the Auth context
      setUserInfo(prevUserInfo => ({
        ...prevUserInfo,
        full_name: newFullName
      }));
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile. Please try again.');
    }
  };

  const validateName = (name) => {
    // Remove leading and trailing whitespace
    const trimmedName = name.trim();
    
    // Check if the name is empty after trimming
    if (trimmedName.length === 0) {
      return { isValid: false, error: "Name cannot be empty." };
    }
    
    // Check if the name exceeds 50 characters
    if (trimmedName.length > 50) {
      return { isValid: false, error: "Name must not exceed 50 characters." };
    }
    
    // Updated regex to match the Python version
    const nameRegex = /^[a-zA-Z\u00C0-\u017F]+(([',. -][a-zA-Z\u00C0-\u017F ])?[a-zA-Z\u00C0-\u017F]*)*$/;
    if (!nameRegex.test(trimmedName)) {
      return { isValid: false, error: "Name can only contain letters, spaces, hyphens, apostrophes, and periods." };
    }
    
    return { isValid: true, error: null };
  };

  const renderProfileTab = () => (
    <div className={styles.tabContent}>
      {user && (
        <>
          <div className={styles.profileHeader}>
            <div className={styles.profileInfo}>
              <div className={styles.profileAvatar}>
                {getInitials(user.full_name || currentUser.displayName || '')}
              </div>
              <div className={styles.profileDetails}>
                {isEditing ? (
                  <input
                    type="text"
                    value={newFullName}
                    onChange={(e) => setNewFullName(e.target.value)}
                    className={styles.editNameInput}
                  />
                ) : (
                  <h3 className={styles.profileName}>
                    {user.full_name || currentUser.displayName}
                  </h3>
                )}
                <p className={styles.profilePlan}>{user.plan || 'Free'} Plan</p>
              </div>
            </div>
            {isEditing ? (
              <button className={styles.saveButton} onClick={handleSaveProfile}>
                Save
              </button>
            ) : (
              <button className={styles.editButton} onClick={handleEditProfile}>
                Update profile
              </button>
            )}
          </div>

          <div className={styles.emailSection}>
            <h3 className={styles.sectionTitle}>Email address</h3>
            <div className={styles.emailInfo}>
              <p>{currentUser.email}</p>
              <span className={styles.emailBadge}>Primary</span>
            </div>
          </div>
        </>
      )}
    </div>
  );

  const renderBillingTab = () => (
    <div className={styles.tabContent}>
      {user && (
        <>
          <div>
            <h3 className={styles.sectionTitle}>Current Plan</h3>
            <div className={styles.planInfo}>
              <div>
                <p className={styles.planName}>{user.plan || 'Free'} Plan</p>
                <p className={styles.planDescription}>
                  {user.plan === 'Free' ? 'Limited features' : 'All features included'}
                </p>
              </div>
              {user.plan === 'Free' && (
                <button className={styles.upgradeButton}>
                  Upgrade to Pro
                </button>
              )}
            </div>
          </div>

          {user.plan !== 'Free' && (
            <div>
              <h3 className={styles.sectionTitle}>Billing History</h3>
              <div className={styles.invoiceList}>
                {invoices.map(invoice => (
                  <div key={invoice.id} className={styles.invoiceItem}>
                    <div>
                      <p className={styles.invoiceDate}>{new Date(invoice.date).toLocaleDateString()}</p>
                      <p className={styles.invoiceDetails}>${invoice.amount.toFixed(2)} - {invoice.status}</p>
                    </div>
                    <button className={styles.downloadButton}>
                      <Download className={styles.icon} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );

  const renderSecurityTab = () => (
    <div className={styles.tabContent}>
      <div className={styles.securitySection}>
        <h3 className={styles.sectionTitle}>Password</h3>
        <button className={styles.securityButton}>
          Change Password
        </button>
      </div>
      <hr className={styles.separator} />
      <div className={styles.deleteAccountSection}>
        <div className={styles.deleteAccountHeader}>
          <h3 className={styles.deleteAccountTitle}>Delete Account</h3>
          <button className={styles.deleteButton}>
            Delete My Account
          </button>
        </div>
        <p className={styles.warningText}>
          Warning: This action cannot be undone. This will permanently delete your account and remove all your data from our servers.
        </p>
      </div>
    </div>
  );

  const handleCloseError = () => {
    setError(null);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>Account</h1>
      </div>
      <div className={styles.content}>
        <div className={styles.tabContainer}>
          {['profile', 'billing', 'security'].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`${styles.tabButton} ${activeTab === tab ? styles.activeTab : ''}`}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>
        
        <div>
          {activeTab === 'profile' && renderProfileTab()}
          {activeTab === 'billing' && renderBillingTab()}
          {activeTab === 'security' && renderSecurityTab()}
        </div>
      </div>
      {error && <ErrorToast message={error} onClose={handleCloseError} />}
    </div>
  );
};

export default ManageAccount;