import React, { useState, useRef } from 'react';
import styles from './VideoRecorder.module.css';
import { getVideoUploadUrls, completeVideoUpload } from '../services/neojiva_api';
import { useAuth } from '../contexts/AuthContext';

const VideoRecorder = ({ onSave, onClose }) => {
  const [receiver, setReceiver] = useState('');
  const [subject, setSubject] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const { currentUser } = useAuth();

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      videoRef.current.srcObject = stream;
      mediaRecorderRef.current = new MediaRecorder(stream);
      
      const chunks = [];
      mediaRecorderRef.current.ondataavailable = (event) => chunks.push(event.data);
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunks, { type: 'video/webm' });
        setRecordedBlob(blob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing media devices:', error);
      setError('Failed to start recording. Please check your camera and microphone permissions.');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach(track => track.stop());
    }
  };

  const uploadVideo = async () => {
    if (!recordedBlob) return;

    try {
      const { video_upload_url, letter_uid } = await getVideoUploadUrls({
        content_type: recordedBlob.type,
        file_size: recordedBlob.size,
        subject,
        email_to_send: receiver,
        creator_user_id: currentUser.uid
      });

      await uploadToGCP(video_upload_url, recordedBlob);

      await completeVideoUpload(letter_uid, {
        letter_uid,
        duration_seconds: videoRef.current.duration,
        creator_user_id: currentUser.uid
      });

      onSave({ letter_uid });
    } catch (error) {
      console.error('Error uploading video:', error);
      setError('Failed to upload video. Please try again.');
    }
  };

  const uploadToGCP = async (uploadUrl, blob) => {
    try {
      const response = await fetch(uploadUrl, {
        method: 'PUT',
        body: blob,
        headers: {
          'Content-Type': blob.type,
        },
      });

      if (!response.ok) {
        throw new Error('Upload failed');
      }

      setUploadProgress(100);
    } catch (error) {
      console.error('Error uploading to GCP:', error);
      throw error;
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Record a Video</h2>
      <div className={styles.inputGroup}>
        <input
          type="email"
          value={receiver}
          onChange={(e) => setReceiver(e.target.value)}
          placeholder="Receiver Email"
          required
          className={styles.input}
        />
        <input
          type="text"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          placeholder="Subject (optional)"
          className={styles.input}
        />
      </div>
      <video ref={videoRef} className={styles.videoPreview} autoPlay muted />
      {error && <p className={styles.error}>{error}</p>}
      <div className={styles.buttonGroup}>
        {!isRecording && !recordedBlob && (
          <button className={styles.recordButton} onClick={startRecording}>Start Recording</button>
        )}
        {isRecording && (
          <button className={styles.stopButton} onClick={stopRecording}>Stop Recording</button>
        )}
        {recordedBlob && (
          <button className={styles.uploadButton} onClick={uploadVideo}>Upload Video</button>
        )}
        <button className={styles.closeButton} onClick={onClose}>Close</button>
      </div>
      {uploadProgress > 0 && (
        <div className={styles.progressContainer}>
          <progress value={uploadProgress} max="100" className={styles.progressBar} />
          <span className={styles.progressText}>{uploadProgress}%</span>
        </div>
      )}
    </div>
  );
};

export default VideoRecorder;