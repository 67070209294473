import React, { useState, useEffect } from 'react';
import styles from './VideoPlayer.module.css';
import { getVideoPlaybackUrl } from '../services/neojiva_api';

const VideoPlayer = ({ letterDetails, onClose }) => {
  const [playbackUrl, setPlaybackUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPlaybackUrl = async () => {
      try {
        const { playback_url } = await getVideoPlaybackUrl(letterDetails.letter_uid);
        setPlaybackUrl(playback_url);
      } catch (err) {
        console.error('Error fetching playback URL:', err);
        setError('Failed to load video. Please try again later.');
      }
    };

    fetchPlaybackUrl();
  }, [letterDetails.letter_uid]);

  return (
    <div className={styles.container}>
      <h2>{letterDetails.subject || 'No subject'}</h2>
      <p>To: {letterDetails.email_to_send}</p>
      {error ? (
        <p className={styles.error}>{error}</p>
      ) : playbackUrl ? (
        <video 
          className={styles.videoPlayer} 
          controls 
          src={playbackUrl}
        >
          Your browser does not support the video tag.
        </video>
      ) : (
        <p>Loading video...</p>
      )}
      <button onClick={onClose} className={styles.closeButton}>
        Close
      </button>
    </div>
  );
};

export default VideoPlayer;